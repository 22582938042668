p {
  font-family: sarabun;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  background-color: rgba(56, 73, 94, 0.7);
}

.ant-pro-sider-menu-sider.light .ant-menu-light,
.ant-layout-sider-children {
  background-color: #FFFFFF;
}
.ant-layout-sider-children .ant-menu-item > a > i {
  color: #000;
}

.ant-layout-sider-children .ant-menu-item > a > span {
  color: #000;
  font-weight: 500;
}
/* MENU */
.ant-design-pro {
  width: 100%;
}

.ant-pro-global-header-trigger {
  /* padding: 0; */
  height: initial;
}

.ant-descriptions-item-label.ant-descriptions-item-colon {
  width: 150px;
}

.ant-pro-sider-menu-sider.light .ant-pro-sider-menu-logo h1 {
  color: #FFF !important;
}

.ant-pro-sider-menu-logo, .ant-pro-global-header {
  background-color: #E83836 !important;
}

.ant-menu-item .anticon > svg {
  display: none;
}

.ant-menu-item .anticon {
  font-family: "Font Awesome 6 pro";
}
.ant-menu-item .anticon.anticon-fab {
  font-family: "Font Awesome 6 brands";
}
.ant-menu-item .anticon::before {
  display: block;
}

.ant-menu-item > a {
  color: #fff !important;
  display: flex !important;
  align-items: center;
}
.ant-pro-sider-menu-sider .ant-menu-inline-collapsed > .ant-menu-item .anticon + span {
  max-width: 0;
}
.ant-layout-sider-collapsed .ant-menu-item .anticon + span {
  margin-left: 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #E83836 !important;
  &::after {
    border-right: #E83836 !important;
  }
  i, span {
    color: #fff !important;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #5D9DD2;
}
.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 !important;
  display: flex;
  justify-content: center;
}

/* SELECT */
.state-error .ant-select-selection{
  border-color: #F87171 !important;
}
.ant-select-selection {
  border-color: #BBCCDC;
}
.ant-select-selection:hover {
  border-color: #5D9DD2;
}
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #5D9DD2;
  box-shadow: 0 0 4px rgba(187, 204, 220, 1);
}
.ant-select-selection__placeholder, .ant-select-search__field__placeholder {
  color: #9e9e9e;
  font-size: 16px;
}
.ant-select-selection__rendered {
  margin-left: 8px;
}
.ant-select-arrow {
  right: 8px;
}
.ant-select-selection-selected-value {
  font-size: 16px;
}
.ant-select-selection--multiple,
.ant-select-selection--single {
  min-height: 34px;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #F8F9FD;
}
.ant-select-dropdown-menu-item {
  font-weight: 300;
  font-size: 16px;
  padding: 8px;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled), .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-selected {
  background-color: #5D9DD2;
  color: #424242;
}
.ant-select-selection--single .ant-select-selection__rendered {
  height: 34px;
  line-height: 32px;
}

/* TAG */
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled), .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled), .ant-select-dropdown-menu-item-selected {
  color: #424242;
  background-color: #F8F9FD;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon, .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  color: #5D9DD2;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: #4ADE80;
}
.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 24px;
  line-height: 1;
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding: 0 16px 0 8px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  font-size: 14px;
  margin-right: 8px;
}
.ant-select-open .ant-select-selection {
  box-shadow: 0 0 4px rgba(187, 204, 220, 1);
}
.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  border-color: #5D9DD2;
  box-shadow: 0 0 4px rgba(187, 204, 220, 1);
}

/* MENU */
.ant-design-pro {
  min-width: 100vw;
}

.ant-pro-global-header-trigger {
  /* padding: 0; */
  height: initial;
  color: #FFF;
  &:hover {
    color: #DDD;
    background-color: #E83836;
  }
}

.ant-pro-basicLayout-content {
  margin: 0;
}

.ant-descriptions-item-label.ant-descriptions-item-colon {
  width: 150px;
}

.ant-menu-item .anticon > svg {
  display: none;
}

.ant-menu-item .anticon {
  font-family: "Font Awesome 6 pro";
}
.ant-menu-item .anticon::before {
  display: block;
}

.ant-menu-item > a {
  display: flex;
  align-items: center;
}

/* DATE PICKER RANGE */
.state-error .ant-input{
  border-color: #F87171 !important;
}
.ant-time-picker-input,
.ant-input {
  height: 34px;
  border-color: #BBCCDC;
}
.ant-time-picker:hover .ant-time-picker-input:not(.ant-input-disabled)
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #5D9DD2;
}
.ant-time-picker:focus .ant-time-picker-input:not(.ant-input-disabled),
.ant-time-picker:active .ant-time-picker-input:not(.ant-input-disabled)
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-calendar-picker:active .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #5D9DD2;
  box-shadow: 0 0 4px rgba(187, 204, 220, 1);
}
.ant-time-picker-input,
.ant-calendar-range-picker-input {
  /* font-family: 'ana'; */
  font-size: 16px;
  font-weight: 300;
  color: #424242;
}
.ant-time-picker-input:placeholder,
.ant-calendar-range-picker-input:placeholder {
  color: #9e9e9e;
}

/* UPLOADER */
.ant-upload-list-item-info {
  padding: 0 18px 0 0;
}
:root .ant-upload-list-item .anticon-close {
  right: 0;
}

/* radio group */
.ant-radio-group {
  font-size: 16px;
}
.ant-radio-button-wrapper {
  padding: 1px 15px;
  height: auto;
}

.ant-pro-basicLayout-content.ant-layout-content {
  position: relative;
  height: 100%;
}

.ant-badge {
  margin: 16px 0 8px 0 !important;
}

.auto-address-input {
  margin: 0 0 16px;
}
.auto-address-input > div > div {
  width: 100%;
}
.auto-address-input > div > div:hover {
  background-color: #21375E !important;
  color: white !important;
}
.auto-address-input > div {
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  border: none;
}
.auto-address-input input {
  border: 1px solid #BBCCDC !important;
  color: rgba(0, 0, 0, 0.65) !important;
  padding: 6px 8px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  border-radius: 2px !important;
  transition: all 0.5s ease !important;
  font-family: 'sarabun' !important;
  width: 100% !important;
  height: auto !important;
}
.auto-address-input input::placeholder {
  color: #D6D6D6 !important;
}
.auto-address-input input:focus {
  outline: 0;
}
.auto-address-input input:hover {
  border: 1px solid #21375E !important;
}
.auto-address-input.empty input{
  border: 1px solid #FC3030 !important;
}
.auto-address-input input + div {
  margin: 4px 0 0;
  border-radius: 2px;
  overflow: auto;
  box-shadow: 0 0 10px #DEDEDE;
  border: none;
  max-height: 250px;
}
.auto-address-input input + div > div {
  color: rgba(0, 0, 0, 0.65) !important;
  padding: 2px 6px !important;
  height: auto !important;
  cursor: pointer;
  background-color: white !important;
  max-height: 200px;
  overflow: auto;
  font-family: 'sarabun' !important;
  border: none !important;
  border-bottom: 1px solid #DEDEDE !important;
}
.auto-address-input input + div > div:hover {
  background-color: #5D9DD2 !important;
  color: white !important;
}

/* Modal Confirm */
.ant-modal-confirm-body .ant-modal-confirm-title, .ant-modal-confirm-content {
  white-space: break-spaces;
}

.slip-section .ant-upload-list {
  position: relative;
}
.slip-section .ant-upload-list .ant-upload-list-item {
  position: absolute;
  top: 4px;
}


.ant-checkbox-inner {
  width: 24px;
  height: 24px;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #2A4472;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2A4472;
  border-color: #2A4472;
}
.ant-checkbox-checked .ant-checkbox-inner::after,
.ant-checkbox-inner::after {
  content: '\f00c' !important;
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300;
  color: white;
  transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
  border: none !important;
  left: 50%;
}

.box .ant-input-number-input {
  text-align: center;
}

.input-comission::placeholder {
  color: #FFFFFF;
  font-weight: 500;
}


text {
  font-size: 14px !important;
  font-family: 'Sarabun' !important;
}

.create-so-btn.ant-btn-group {
  height: 43px;
  background-color: #4ADE80;
  color: #FFF;
  border-color: #4ADE80;
}

.create-so-btn.ant-btn-group .ant-btn {
  height: 100%;
  background-color: #4ADE80;
  color: #FFF;
  border-color: #4ADE80;
  /* border-left-color: #FFF; */
}

.delivery-channel-setting .ant-radio-button-wrapper {
  height: 32px;
}