body {
  margin: 0;
  padding: 0;
  font-family: "sarabun", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --antd-wave-shadow-color: #BBCCDC;
  color: #424242;
}

button,
p {
  font-family: sarabun !important;
}

span {
  font-family: sarabun;
}

textarea {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "extra-light";
  src: url('./styles/fonts/NanumGothic-Regular.ttf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "medium";
  src: url('./styles/fonts/NanumGothic-Bold.ttf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "semi-bold";
  src: url('./styles/fonts/NanumGothic-ExtraBold.ttf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "prajad";
  src: url('./styles/fonts/CSPraJad.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "prajad-bold";
  src: url('./styles/fonts/CSPraJad-bold.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "ana";
  src: url('./styles/fonts/Anakotmai-Light.ttf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "ana";
  src: url('./styles/fonts/Anakotmai-Medium.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ana";
  src: url('./styles/fonts/Anakotmai-Bold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

textarea {
  outline: 0;
  border-radius: 2px;
  padding: 6px 8px;
  font-size: 16px;
  font-family: 'ana';
  font-weight: 300;
  transition: all 0.5s ease;
}
textarea:hover {
  border: 1px solid #5D9DD2;
}

.facebook-login-button {
  width: 100%;
  background-color: #1877F2;
  color: #ffffff;
}